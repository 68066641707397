import React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Ar training eula | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <h1>AR Training End-User License Agreement (EULA)</h1>
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <p style={{ color: "#9c9c9c" }}>Last updated August 11, 2021</p>
          </div>
          <div id="footer_page_width2">
            <p>
              This End-User License Agreement ("EULA") is a legal agreement
              between you and Custom Surgical GmbH.
            </p>
            <p>
              This EULA agreement governs your acquisition and use of our AR
              Training software ("Software") directly from Custom Surgical GmbH
              or indirectly through a Custom Surgical GmbH authorized reseller
              or distributor (a "Reseller").
            </p>
            <p>
              Please read this EULA agreement carefully before completing the
              installation process and using the AR Training software. It
              provides a license to use the AR Training software and contains
              warranty information and liability disclaimers.
            </p>
            <p>
              If you register for a free trial of the AR Training software, this
              EULA agreement will also govern that trial. By clicking "accept"
              or installing and/or using the AR Training software, you are
              confirming your acceptance of the Software and agreeing to become
              bound by the terms of this EULA agreement.
            </p>
            <p className="container3 reveal fade-left">
              If you are entering into this EULA agreement on behalf of a
              company or other legal entity, you represent that you have the
              authority to bind such entity and its affiliates to these terms
              and conditions. If you do not have such authority or if you do not
              agree with the terms and conditions of this EULA agreement, do not
              install or use the Software, and you must not accept this EULA
              agreement.
            </p>
            <p className="container3 reveal fade-left">
              This EULA agreement shall apply only to the Software supplied by
              Custom Surgical GmbH herewith regardless of whether other software
              is referred to or described herein. The terms also apply to any
              Custom Surgical GmbH updates, supplements, Internet-based
              services, and support services for the Software, unless other
              terms accompany those items on delivery. If so, those terms apply.
            </p>
            <h3 className="container3 reveal fade-right">License Grant</h3>
            <p className="container3 reveal fade-left">
              Custom Surgical GmbH hereby grants you a personal,
              non-transferable, non-exclusive licence to use the AR Training
              software on your devices in accordance with the terms of this EULA
              agreement.
            </p>
            <p className="container3 reveal fade-left">
              You are permitted to load the AR Training software (for example a
              PC, laptop, mobile or tablet) under your control. You are
              responsible for ensuring your device meets the minimum
              requirements of the AR Training software.
            </p>
            <p className="container3 reveal fade-left">
              You are not permitted to:
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Edit, alter, modify, adapt, translate or otherwise change
              the whole or any part of the Software nor permit the whole or any
              part of the Software to be combined with or become incorporated in
              any other software, nor decompile, disassemble or reverse engineer
              the Software or attempt to do any such things
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Reproduce, copy, distribute, resell or otherwise use the
              Software for any commercial purpose
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Allow any third party to use the Software on behalf of or
              for the benefit of any third party
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Use the Software in any way which breaches any applicable
              local, national or international law
            </p>
            <p className="container3 reveal fade-left">
              &#8226; Use the Software for any purpose that Custom Surgical GmbH
              considers is a breach of this EULA agreement
            </p>
            <h3 className="container3 reveal fade-right">
              Intellectual Property and Ownership
            </h3>
            <p className="container3 reveal fade-left">
              Custom Surgical GmbH shall at all times retain ownership of the
              Software as originally downloaded by you and all subsequent
              downloads of the Software by you. The Software (and the copyright,
              and other intellectual property rights of whatever nature in the
              Software, including any modifications made thereto) are and shall
              remain the property of Custom Surgical GmbH.
            </p>
            <p className="container3 reveal fade-left">
              Custom Surgical GmbH reserves the right to grant licences to use
              the Software to third parties.
            </p>
            <h3 className="container3 reveal fade-right">Termination</h3>
            <p>
              This EULA agreement is effective from the date you first use the
              Software and shall continue until terminated. You may terminate it
              at any time upon written notice to Custom Surgical GmbH.
            </p>
            <p className="container3 reveal fade-left">
              It will also terminate immediately if you fail to comply with any
              term of this EULA agreement. Upon such termination, the licenses
              granted by this EULA agreement will immediately terminate and you
              agree to stop all access and use of the Software. The provisions
              that by their nature continue and survive will survive any
              termination of this EULA agreement.
            </p>
            <h3 className="container3 reveal fade-right">Governing Law</h3>
            <p className="container3 reveal fade-left">
              This EULA agreement, and any dispute arising out of or in
              connection with this EULA agreement, shall be governed by and
              construed in accordance with the laws of Germany.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
